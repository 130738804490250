import React from 'react';
import CallToActionCard from '../../../call-to-action/card';
import SectionTitle from '../../../section-title';
import * as style from './index.module.scss';

export default function SectionActions() {
  const cardList: JSX.Element[] = [
    <CallToActionCard
      className="col"
      key={'home'}
      title={`I'm looking to sell or buy home.`}
      actionLabel="Start"
      actionHref=""
    />,
    <CallToActionCard
      className="col"
      key={'agent'}
      title={`I'm an agent looking to help.`}
      actionLabel="Go"
      actionHref=""
    />
  ];

  return (
    <section className={style.sectionActions + ' overflow-hidden'}>
      <div className={'container'}>
        <header>
          <SectionTitle className="text-md-center">
            Search for your next home, from home.
          </SectionTitle>
        </header>

        <div className={style.cardList + ' row flex-column flex-sm-row'}>{cardList}</div>
      </div>
    </section>
  );
}
