import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { EquityIntoCashQuery } from '../../../../../graphql-types';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import ParallaxBackground from '../../../parallax-background';
import * as style from './index.module.scss';

export default function ApprovedRealtors() {
  const data: FeatureSectionProps = {
    items: [
      {
        title: 'Connect Quickly with top Realtors.',
        subtitle: 'Nuborrow approved realtors',
        description: (
          <>
            You deserve the best representation when buying your new home, or when navigating the
            selling process. <br /> <br /> With Nuborrow and our Approved Realtor program you are
            never alone. You search for your dream home and our team will work with you to find the
            best deal possible.
          </>
        ),
        actions: [],
        art: approvedRealtorsArt()
      }
    ]
  };

  return <FeatureSection {...data} className={style.callUs} />;
}

export const approvedRealtorsArt = () => {
  const bgImage = useStaticQuery<EquityIntoCashQuery>(dataQuery);

  return (
    <div className={'d-flex align-items-center justify-content-center'}>
      <ParallaxBackground className={style.art} image={bgImage.file as unknown as ImageDataLike} />
    </div>
  );
};

const dataQuery = graphql`
  query ApprovedRealtorsQuery {
    file(relativePath: { glob: "photos/realtors.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.625, width: 1000)
      }
      relativePath
    }
  }
`;
