import React from 'react';
import PerspectiveCard from '../../../perspective-card';
import RoundBtn from '../../../round-btn';
import VisualTitle from '../../../visual-title';
import * as style from './index.module.scss';

export interface BannerSectionProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
}

export default function FindBanner() {
  const items: { title: string; desc: string }[] = [
    {
      title: 'Work with a highly rated local realtor.',
      desc: 'We only recruit top-performing agents who are committed to helping you make your next big move.'
    },
    {
      title: 'Find your dream home and perfect agent.',
      desc: 'Our real estate agents love working with our Nuborrow family of client with our customized match making skills.'
    }
  ];

  return (
    <section className={`${style.banner} d-block overflow-hidden position-relative m-auto`}>
      <div className="container position-relative h-100 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
        <div
          className={' text-center d-flex flex-column align-items-center justify-content-center'}
        >
          <VisualTitle className={' mb-3 mb-md-4'}>
            Find a home, an agent, and a mortgage with a click.
          </VisualTitle>

          <div className={style.details + ' d-flex flex-column flex-md-row'}>
            {items.map((item) => (
              <div key={item.title} className={style.item}>
                <h2>{item.title}</h2>
                <p>{item.desc}</p>
              </div>
            ))}
          </div>

          <div
            className={`${style.actions} d-flex align-self-stretch align-self-sm-center flex-column flex-lg-row`}
          >
            <PerspectiveCard className={style.perspectiveCard} intensity={25}>
              <RoundBtn className={style.primaryBtn} href="https://shopwithnuborrow.com" isPrimary>
                Find your dream home
              </RoundBtn>
            </PerspectiveCard>
          </div>
        </div>
      </div>
    </section>
  );
}
