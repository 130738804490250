import React from 'react';
import Layout from '../components/layout';
import ApprovedRealtors from '../components/sections/find-home/approved-realtors';
import FindBanner from '../components/sections/find-home/banner';
import EverythingOnline from '../components/sections/find-home/everything-online';
import SectionActions from '../components/sections/find-home/search-actions';
import StartPacking from '../components/sections/find-home/start-packing';

export default function FindHomePage() {
  return (
    <Layout pageTitle={'Find a Home'}>
      <FindBanner />
      <ApprovedRealtors />
      <StartPacking />
      <EverythingOnline />
      <SectionActions />
    </Layout>
  );
}
